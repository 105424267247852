$(document).ready(function() {

    if ( $('.profile-slider').length ) {
        $('.profile-slider').unslider({
            activeClass: 'active'
        })
    }

    $('.toggle-categories a').click(function(e) {
        e.preventDefault();

        $('.categories-listing li.toggle-more').slideToggle(200);
        $(this).toggleClass('show-less');
    })


    $("a.thickbox").fancybox({
        openEffect	: 'none',
        closeEffect	: 'none'
    });


    /*$.ajax({
        type     : 'GET',
        url      : 'http://ego-deals.com/api/vsichkioferti.php',
        dataType : 'xml',
        crossDomain: true,
        success  : function( data, textStatus, jqXhr ) {
            console.log(data)
            showOffers(data)
        },
        error: function(jqXhr) {
            if( jqXhr.status === 422 )
                showErrors(form, jqXhr.responseJSON);
        }
    })

    function showOffers(offers) {
        $(offers).find('offer').each(function(index) {
            var
                $title = $(this).find('title').html()

            console.log($title)
        })
    }
    */
})
